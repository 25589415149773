import axios from 'axios'
import consti from '../const/index'
import { useState } from '@/store/store'

export default {
  login: loginData => new Promise((success, failure) => {
    axios.post(consti.baseUrl + consti.login, loginData)
      .then(response => {
        const data = response.data
        localStorage.setItem('token', data.token)

        success(data)
      })
      .catch(error => {
        localStorage.removeItem('token')
        const state = useState()

        if (state) {
          state.setUser(null)
        }

        failure(error)
      })
  })
}
