export default {
  baseUrl: process.env.VUE_APP_BASE_URL,
  login: '/login',
  categories: '/categories',
  users: '/users',
  posts: '/posts',
  stats: '/stats',
  subscriptions: '/subscriptions',
  messages: '/messages',
  gallery: '/gallery',
  impressions: '/impressions',
  experiences: '/experiences',
  getUserInfo: '/get-user-info',
  upload: '/upload',

  postComponents: {
    heading: 'heading',
    paragraph: 'paragraph',
    code: 'code_snippet',
    image: 'carousel_image'
  },

  pTypes: {
    default: 'default',
    notice: 'notice',
    attention: 'attention',
  },

  languages: {
    cpp: 'cpp',
    cs: 'csharp',
    php: 'php',
    js: 'javascript',
    markup: 'markup',
    bash: 'bash',
    json: 'json',
    sql: 'sql',
    yaml: 'yaml',
    properties: 'properties'
  }
}
