import { reactive, inject, readonly } from 'vue'

export const stateSymbol = Symbol('state')
export const useState = () => inject(stateSymbol)

export const createStore = () => {
  const state = reactive({
    user: {
      id: null,
      name: null,
      email: null,
      bio: null,
      designation: null,
      image: null,
      role: null,
      token: null,
      experiences: []
    },
    postId: null
  });
  const getUser = () => state.user;
  const setUser = (user) => state.user = user;
  const getPostId = () => state.postId;
  const setPostId = (postId) => state.postId = postId;

  return { getUser, setUser, getPostId, setPostId, state: readonly(state) };
}
