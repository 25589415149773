<template>
	<div v-if="isLoggedIn" class="container-fluid h-100">
		<div class="row h-100 p-0">
			<div class="col-auto p-0">
				<hamburger-menu />
			</div>
			<div class="col p-0 main-content">
				<router-view />
			</div>
		</div>
	</div>

	<login v-else></login>
</template>

<script>
import HamburgerMenu from '@/components/HamburgerMenu'
import Login from '@/views/Login'
import {useState} from '@/store/store'

export default {
	name: 'App',
	components: {Login, HamburgerMenu},
  created() {
    this.checkLogin()
  },
  setup() {
		return {
			state: useState()
		}
	},
	watch: {
		$route(to, from) {
			this.checkLogin()
		}
	},
  data() {
		return {
			isLoggedIn: false
		}
	},
  methods: {
    checkLogin() {
      this.isLoggedIn = localStorage.getItem('token') !== null

      if (!this.isLoggedIn) {
        this.state.setUser(null)
        this.$router.push({name: 'Login'})
      }
    }
  }
}
</script>

<style lang="scss">

@font-face {
	font-family: "Inconsolata";
	src: local("Inconsolata"),
	url(./fonts/inconsolata.ttf) format("truetype");
}

html {
	overflow-y: hidden;
}

#app {
	font-family: 'Inconsolata', monospace;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background-color: #f8f9fa;
	height: 100%;
	font-size: 13px;
}

.main-content {
	overflow-y: auto;
	right: 0;
	top: 0;
	bottom: 0;
  margin-left: 250px;
  height: 100%;
}

</style>
