<template>
  <div class="body">
    <div class="login-container shadow-lg">
      <h3>Blog Management System</h3>
      <hr>
      <form @submit.prevent="submit">
        <div class="form-group">
          <input v-model="email"
                 @input="validateEmail"
                 id="email"
                 class="form-control"
                 type="email"
                 placeholder="Email Address"
                 required>
          <small v-if="errorMessage"
                 class="form-text font-weight-bold text-danger text-left">
            {{ errorMessage }}
          </small>
        </div>

        <div class="form-group">
          <input v-model="password"
                 id="password"
                 class="form-control"
                 type="password"
                 placeholder="Password"
                 required>
        </div>

        <button v-bind:disabled="!validated"
                type="submit"
                class="btn btn-primary btn-block">
          <span v-if="loading" class="spinner-border spinner-border-sm"></span>
          {{ loginButtonText }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>

  import auth from '../auth/index'
  import { useState } from '@/store/store'

  export default {
    name: 'Login',
    setup() {
      return {
        state: useState()
      }
    },
    mounted() {
      document.title = 'BMS | Login'
    },
    computed: {
      validated() {
        return this.emailValidated && this.validatePassword()
      },
      loginButtonText() {
        return this.loading ?
          'LOADING...' :
          'LOGIN'
      }
    },
    data() {
      return {
        email: null,
        password: null,
        errorMessage: null,
        emailValidated: false,
        loading: false
      }
    },
    methods: {
      submit() {
        this.errorMessage = null
        this.loading = true

        auth.login({
          email: this.email,
          password: this.password
        })
        .then(data => {
          this.loading = false
          this.state.setUser({
            id: data.id,
            name: data.name,
            email: data.email,
            designation: data.designation,
            bio: data.bio,
            imageUrl: data.avatar,
            role: data.role,
            token: data.token,
            experiences: data.experiences
          })
          this.$router.push({ name: "Dashboard" })
        })
        .catch(error => {
          this.loading = false
          this.errorMessage = error.response.data.message
        })
      },
      validateEmail() {

        const re = /\S+@\S+\.\S+/
        this.emailValidated = re.test(this.email)

        if (!!!this.email) {
          this.errorMessage = null
          return false
        }

        if (!this.emailValidated) {
          this.errorMessage = "Invalid email address"
        } else {
          this.errorMessage = null
        }

        return this.emailValidated
      },
      validatePassword() {
        return this.password !== null && this.password.length >= 6
      }
    }
  }
</script>

<style lang="scss">

  html, body, #app {
    height: 100%;
    margin: 0;
  }

  .body {
    background-image: linear-gradient(to right, blue, blueviolet);
    height: 100%;
    display: flex;
  }

  .login-container {
    margin: 120px auto auto auto;
    width: 320px;
    background: #cfdeec;
    padding: 30px 20px 30px 20px;
  }

</style>
