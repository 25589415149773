<template>

	<div v-if="!createMode" class="d-flex card">
		<div class="cover-image" :style="imageStyle"></div>
		<div class="card-body d-flex flex-column p-0 pt-2">
			<h5 class="card-title blur">{{ defaultData.name }}</h5>
			<p class="card-text blur">{{ defaultData.description }}</p>

			<div class="mt-auto">
				<button type="button" class="btn btn-info" @click.prevent="switchMode">
					<i class="bi bi-plus-circle"></i>
				</button>
			</div>
		</div>
	</div>

	<div v-else class="d-flex card">
		<label for="image_upload">
			<div class="cover-image" :style="imageStyle"></div>
		</label>
		<input id="image_upload" type="file" class="form-control d-none" @change="onImageSelect">

		<div class="card-body d-flex flex-column p-0 pt-2">
			<div class="form-group">
				<input type="text" class="form-control" v-model="createData.name" @input="validateTitle">
			</div>
			<div class="form-group">
				<textarea :style="textAreaHeight" class="form-control" v-model="createData.description">{{ createData.description }}</textarea>
			</div>

			<small v-if="errorMessage"
			       class="form-text font-weight-bold text-danger text-center">
				{{ errorMessage }}
			</small>

			<div class="container-fluid mt-auto">
				<div class="row p-0">
					<div class="col-6 pr-1 pl-1">
						<button type="button" class="btn btn-success" v-bind:disabled="errorMessage || loading"
						        @click.prevent="create">
							<span v-if="loading" class="spinner-border spinner-border-sm"></span>
							<i v-else class="bi bi-check-square"></i>
						</button>
					</div>

					<div class="col-6 pr-1 pl-1">
						<button type="button" class="btn btn-info" v-bind:disabled="loading"
						        @click.prevent="switchMode">
							<i class="bi bi-pencil-square"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import request from '@/request'
import consti from '@/const'

export default {
	name: "AddCategory",
	computed: {
		imageStyle() {
			return {
				'background': 'url("' + this.createData.imageUrl + '")'
			}
		},
		textAreaHeight() {
			return {
				'height': this.errorMessage ?
					'65px' :
					'120px'
			}
		}
	},
	data() {
		return {
			loading: false,
			createMode: false,
			errorMessage: null,
			defaultData: {
				name: 'GMBlog',
				description: 'Category',
				imageUrl: require('../assets/default-blur.jpg')
			},
			createData: {
				imageUrl: require('../assets/default-blur.jpg')
			}
		}
	},
	methods: {
		switchMode() {
			this.createMode = !this.createMode
			this.errorMessage = null
		},
		onImageSelect(e) {
			const file = e.target.files[0]

			let formData = new FormData();
			formData.append('files[]', file)

			this.loading = true

			request.submitFormData(consti.baseUrl + consti.upload, formData, {
				'Content-Type': 'multipart/form-data'
			}, this.$router)
				.then(data => {
					this.loading = false
					console.log(data)
					this.createData.imageUrl = data[0]
					this.errorMessage = null
				}, error => {
					this.loading = false
					console.log(error)
				})
		},
		validateTitle() {
			if (this.createData.name != null && this.createData.name.length === 0) {
				this.errorMessage = "The title of the category can't be empty"
			} else {
				this.errorMessage = null
			}
		},
		create() {

			if (!this.createData.imageUrl) {
				this.errorMessage = 'Image is required'
				return
			}

			let formData = new FormData();

			formData.append('name', this.createData.name)
			if (this.createData.description) {
				formData.append('description', this.createData.description)
			}
			formData.append('cover_image', this.createData.imageUrl)

			this.loading = true

			request.submitFormData(consti.baseUrl + consti.categories, formData, {
				'Content-Type': 'multipart/form-data'
			}, this.$router)
				.then(data => {
					this.loading = false

					this.$emit('category-created')
					this.reset()
					this.createMode = false
				}, error => {
					this.loading = false
				})
		},
		reset() {
			this.createData = {
				image: null,
				imageUrl: require('../assets/default-blur.jpg')
			}
		}
	}
}
</script>

<style scoped>

.blur {
	color: transparent;
	text-shadow: 0 0 15px #000;
}

.cover-image {
	background-position: center;
	background-size: cover;
	height: 120px;
}

.card {
	width: 200px;
	padding: 1rem;
	margin: 1rem;
	height: 400px;
	display: inline-block;
	cursor: pointer;
}

.card:hover {
	background-color: #cfdeec;
}

textarea {
	resize: none;
	height: 120px;
}

</style>
