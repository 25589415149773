<template>

  <div v-if="loading" class="d-flex justify-content-center mt-5">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div v-else class="container-fluid">
    <div class="row p-0">
      <div v-for="(category, index) in categories" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <category-card :id="category.id"
                       :name="category.name"
                       :description="category.description"
                       :image-url="category.cover_image" />
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <add-category @category-created="fetchData"></add-category>
      </div>
    </div>
  </div>
</template>

<script>
  import CategoryCard from '@/components/CategoryCard'
  import request from '../request/index'
  import consti from '../const/index'
  import AddCategory from '@/components/AddCategory'

  export default {
    name: "Categories",
    components: { CategoryCard, AddCategory },
    created() {
      this.fetchData()
    },
    mounted() {
      document.title = 'BMS | Categories'
    },
    data() {
      return {
        loading: true,
        categories: []
      }
    },
    methods: {
      fetchData() {
        request.get(consti.baseUrl + consti.categories, this.$router)
          .then(data => {
            this.loading = false
            this.categories = data
            console.log(this.categories)
          }, error => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped>

</style>
