<template>
	<div :class="'menu d-sm-block bg-light border-right sticky-top' + (!show ? ' d-none' : '')">
		<div class="sidebar-heading d-none d-sm-block">BMS</div>
		<div class="list-group list-group-flush bg-primary">
			<router-link class="list-group-item list-group-item list-group-item-action text-dark"
			             active-class="active"
			             :to="{ name: 'Dashboard' }">Dashboard
			</router-link>
      <router-link class="list-group-item list-group-item-action text-dark"
                   active-class="active"
                   :to="{ name: 'Subscriptions' }">Subscriptions
      </router-link>
      <router-link class="list-group-item list-group-item-action text-dark"
                   active-class="active"
                   :to="{ name: 'Messages' }">Messages
      </router-link>
			<router-link class="list-group-item list-group-item-action text-dark"
			             active-class="active"
			             :to="{ name: 'Bloggers' }">User Management
			</router-link>
			<router-link class="list-group-item list-group-item-action text-dark"
			             active-class="active"
			             :to="{ name: 'Posts' }">Posts
			</router-link>
			<router-link class="list-group-item list-group-item-action text-dark"
			             active-class="active"
			             :to="{ name: 'Categories' }">Categories
			</router-link>
			<router-link class="list-group-item list-group-item-action text-dark"
			             active-class="active"
			             :to="{ name: 'Profile Information' }">Profile Information
			</router-link>
			<a class="list-group-item list-group-item-action text-dark logout-button"
			   @click="logout">Logout</a>
		</div>
	</div>

</template>

<script>
export default {
	name: 'HamburgerMenu',
	data() {
		return {
			show: true
		}
	},
	methods: {
		logout() {
			localStorage.removeItem('token')
			this.$router.push({name: 'Login'})
		}
	}
}
</script>

<style scoped>

.list-group-item {
	background-color: #cfdeec;
}

.list-group-item.active {
	background-color: #f8f9fa;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:last-child {
	border-bottom-width: 1px;
}

.logout-button {
	cursor: pointer;
}

.menu-button {
	right: 16px;
	top: 8px;
	z-index: 2;
	background-color: white;
}

.navbar-toggler {
	border: 1px solid #2c3e50;
	box-shadow: 2px 2px 1px grey;
}

@media (max-width: 576px) {
	.menu {
		margin: 1.5rem auto auto;
		border-right: none !important;
		background: transparent;
	}
}

.menu {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
}

</style>
