import axios from 'axios'

export default {
  get: (url, router) => new Promise((resolve, reject) => {
    axios.get(url, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => {
        const data = response.data
        resolve(data)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('token')
          router.push({name: 'Login'})
        }

        reject(error)
      })
  }),
  submitFormData: (url, data, additionalHeaders, router) => new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: Object.assign({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }, additionalHeaders)
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('token')
          router.push({name: 'Login'})
        }

        reject(error)
      })
  }),
  updateFormData: (url, data, additionalHeaders, router) => new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: Object.assign({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }, additionalHeaders)
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('token')
          router.push({name: 'Login'})
        }

        reject(error)
      })
  }),
  post: (url, data, router) => new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => {
      resolve(response.data)
    })
      .catch(error => {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        router.push({name: 'Login'})
      }

      reject(error)
    })
  }),
  put: (url, data, router) => new Promise((resolve, reject) => {
    axios.put(url, data, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('token')
          router.push({name: 'Login'})
        }

        reject(error)
      })
  }),
  delete: (url, router) => new Promise((resolve, reject) => {
    axios.delete(url, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem('token')
          router.push({name: 'Login'})
        }

        reject(error)
      })
  })
}
